import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

const ManagementAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			auth: authRoles.onlyOrgAdmin,
			path: '/apps/management/organizations/org-admin-view',
			component: React.lazy(() => import('./organizations/organizationsOrgAdminView'))
		},
		{
			auth: authRoles.instructor,
			path: '/apps/management/organizations/:id',
			component: React.lazy(() => import('./courses/Courses'))
		},
		{
			auth: authRoles.admin,
			path: '/apps/management/organizations',
			component: React.lazy(() => import('./organizations/organizations'))
		},
		{
			auth: authRoles.onlyInstructor,
			path: '/apps/management/courses/instructor-view',
			component: React.lazy(() => import('./courses/CoursesInstructorView'))
		},
		{
			auth: authRoles.instructor,
			path: '/apps/management/course/:id/students',
			component: React.lazy(() => import('./course/students/students'))
		},
		{
			auth: authRoles.instructor,
			path: '/apps/management/course/:id',
			component: React.lazy(() => import('./course/Course'))
		},
		{
			auth: authRoles.instructor,
			path: '/apps/management/test/:id',
			component: React.lazy(() => import('./test/test'))
		},
		{
			auth: authRoles.instructor,
			path: '/apps/management/courses/:id/testRecords/:testRecordId',
			component: React.lazy(() => import('./course/testRecords/testRecord'))
		},
		{
			auth: authRoles.instructor,
			path: '/apps/management/courses/:id/testRecords',
			component: React.lazy(() => import('./course/testRecords/testRecords'))
		},
		{
			auth: authRoles.orgAdmin,
			path: '/apps/management/users',
			component: React.lazy(() => import('./users/users'))
		},
		{
			auth: authRoles.instructor,
			path: '/apps/management/levelTest/:testRecordId',
			component: React.lazy(() => import('./levelTest/editLevelTestRecord'))
		},
		{
			auth: authRoles.instructor,
			path: '/apps/management/levelTest',
			component: React.lazy(() => import('./levelTest/levelTests'))
		},
		{
			auth: authRoles.admin,
			path: '/apps/management',
			component: () => <Redirect to="/apps/management/organizations" />
		}
	]
};

export default ManagementAppConfig;
