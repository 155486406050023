import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { submitLogin } from 'app/auth/store/loginSlice';

export default function AutoLogin() {
	const params = useParams();
	const dispatch = useDispatch();
	const userId = params.username;
	const history = useHistory();

	useEffect(() => {
		const autoLogin = async () => {
			try {
				dispatch(submitLogin(userId, '1234', history));
			} catch (error) {
				console.log(error);
			}
		};
		autoLogin();
	}, [dispatch, history, userId]);
	return <div>{userId}</div>;
}
