import { authRoles } from 'app/auth';

const navigationConfig = [
	{
		id: 'management',
		title: '管理',
		translate: 'management',
		type: 'group',
		icon: 'apps',
		auth: authRoles.instructor,
		children: [
			{
				id: 'organizationsOrgAdminView',
				title: '机构',
				translate: 'organizations',
				type: 'item',
				url: '/apps/management/organizations/org-admin-view',
				icon: 'school',
				auth: authRoles.onlyOrgAdmin
			},
			{
				id: 'organizations',
				title: '机构',
				translate: 'organizations',
				type: 'item',
				url: '/apps/management/organizations',
				icon: 'school',
				auth: authRoles.admin
			},
			// {
			// 	id: 'users',
			// 	title: '用户',
			// 	translate: 'users',
			// 	type: 'item',
			// 	url: '/apps/management/users',
			// 	icon: 'school',
			// 	auth: authRoles.orgAdmin
			// },
			{
				id: 'coursesInstructorView',
				title: '课程',
				translate: 'courses',
				type: 'item',
				url: '/apps/management/courses/instructor-view',
				icon: 'school',
				auth: authRoles.onlyInstructor
			}
			// {
			// 	id: 'levelTest',
			// 	title: '考级管理',
			// 	translate: 'level test',
			// 	type: 'item',
			// 	url: '/apps/management/levelTest',
			// 	icon: 'school',
			// 	auth: authRoles.instructor
			// }
		]
	},
	{
		id: 'academy',
		title: '学习',
		translate: 'study',
		type: 'group',
		icon: 'apps',
		auth: authRoles.onlyStudent,
		children: [
			{
				id: 'courses',
				title: '课程列表',
				translate: 'courses',
				type: 'item',
				url: '/apps/academy/courses',
				icon: 'school'
			}
			// {
			// 	id: 'works',
			// 	title: '作品集',
			// 	translate: 'projects',
			// 	type: 'item',
			// 	url: '/apps/works/allworks',
			// 	icon: 'school'
			// }
		]
	},
	// {
	// 	id: 'personal',
	// 	title: '个人',
	// 	translate: 'my',
	// 	type: 'group',
	// 	icon: 'apps',
	// 	auth: authRoles.student,
	// 	children: [
	// 		{
	// 			id: 'testRecords',
	// 			title: '我的考试',
	// 			translate: 'my test',
	// 			type: 'item',
	// 			url: '/apps/individual/testRecords',
	// 			icon: 'school',
	// 			auth: authRoles.onlyStudent
	// 		},
	// 		{
	// 			id: 'myCourses',
	// 			title: '我的课程',
	// 			translate: 'my courses',
	// 			type: 'item',
	// 			url: '/apps/academy/myCourses',
	// 			icon: 'school',
	// 			auth: authRoles.onlyStudent
	// 		},
	// 		{
	// 			id: 'myWork',
	// 			title: '我的作品',
	// 			translate: 'my projects',
	// 			type: 'item',
	// 			url: '/apps/works/myworks',
	// 			icon: 'school'
	// 		},
	// 		{
	// 			id: 'myLevel',
	// 			title: '我的考级',
	// 			translate: 'my level test',
	// 			type: 'item',
	// 			url: '/apps/individual/level',
	// 			icon: 'school',
	// 			auth: authRoles.onlyStudent
	// 		},
	// 		{
	// 			id: 'myInstructor',
	// 			title: '我的导师',
	// 			translate: 'my instructors',
	// 			type: 'item',
	// 			url: `/apps/individual/instructors`,
	// 			icon: 'school',
	// 			auth: authRoles.onlyStudent
	// 		},
	// 		{
	// 			id: 'info',
	// 			title: '个人信息',
	// 			translate: 'my information',
	// 			type: 'item',
	// 			url: '/apps/individual/info',
	// 			icon: 'school'
	// 		},
	// 		{
	// 			id: 'profile',
	// 			title: '个人页面',
	// 			translate: 'my page',
	// 			type: 'item',
	// 			url: `/apps/individual/profile`,
	// 			icon: 'school'
	// 		},
	// 		{
	// 			id: 'my-questions',
	// 			title: '我的题目',
	// 			type: 'item',
	// 			url: `/apps/individual/my-questions`,
	// 			icon: 'school',
	// 			auth: authRoles.onlyInstructor
	// 		},
	// 		{
	// 			id: 'my-students',
	// 			title: '我的学生',
	// 			type: 'item',
	// 			url: `/apps/individual/my-students`,
	// 			icon: 'school',
	// 			auth: authRoles.onlyInstructor
	// 		}
	// 	]
	// },
	{
		id: 'auth',
		title: '认证',
		translate: 'authentication',
		type: 'group',
		icon: 'apps',
		children: [
			{
				id: 'login',
				title: '登录',
				translate: 'login',
				type: 'item',
				url: '/login',
				auth: authRoles.onlyGuest,
				icon: 'lock'
			},
			// {
			// 	id: 'register',
			// 	title: '注册',
			// 	type: 'item',
			// 	url: '/register',
			// 	auth: authRoles.onlyGuest,
			// 	icon: 'person_add'
			// },
			{
				id: 'logout',
				title: '登出',
				translate: 'logout',
				type: 'item',
				auth: authRoles.student,
				url: '/logout',
				icon: 'exit_to_app'
			}
		]
	}
];

export default navigationConfig;
