import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

// function componentLoader(lazyComponent, attemptsLeft) {
//   return new Promise((resolve, reject) => {
//     lazyComponent()
//       .then(resolve)
//       .catch((error) => {
//         // let us retry after 1500 ms
//         setTimeout(() => {
//           if (attemptsLeft === 1) {
//             reject(error);
//             return;
//           }
//           componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
//         }, 1500);
//       });
//   });
// }

const AcademyAppConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      auth: authRoles.student,
      path: '/apps/academy/courses/:courseId/exam',
      component: React.lazy(() => import('./exam/Exam'))
    },
    {
      auth: authRoles.student,
      path: '/apps/academy/courses/:courseId',
      component: React.lazy(() => import('./course/Course'))
    },
    {
      auth: authRoles.student,
      path: '/apps/academy/courses',
      component: React.lazy(() => import('./courses/Courses'))
    },
    {
      auth: authRoles.student,
      path: '/apps/academy/myCourses',
      component: React.lazy(() => import('./myCourses/myCourses'))
    },
    {
      auth: authRoles.student,
      path: '/apps/academy',
      component: () => <Redirect to="/apps/academy/courses" />
    }
  ]
};

export default AcademyAppConfig;
