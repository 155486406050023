import FuseAnimate from '@fuse/core/FuseAnimate';
import { useForm } from '@fuse/hooks';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { submitLogin } from '../../auth/store/loginSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
	root: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
			theme.palette.primary.dark,
			0.5
		)} 100%)`,
		color: theme.palette.primary.contrastText
	},
	image: {
		maxWidth: '100%',
		maxHeight: '100%'
	},
	red: {
		color: 'red'
	},
	jumbtron: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
		padding: '16px'
	},
	content: {
		marginTop: '-200px',
		textAlign: 'center'
	}
}));

function Login2Page() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();


	const { form, handleChange, resetForm } = useForm({
		email: '',
		password: '',
		remember: true
	});

	function isFormValid() {
		return form.email.length > 0 && form.password.length > 0;
	}

	async function handleSubmit(ev) {
		ev.preventDefault();
		const { email, password } = form;
		dispatch(submitLogin(email, password, history));
		resetForm();
	}

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 p-24 md:flex-row md:p-0')}>
			<div
				className="flex flex-col flex-grow-0 items-center text-white text-center md:items-start md:flex-shrink-0 md:flex-1 md:text-left"
				style={{
					backgroundImage: `url('assets/images/entry/mobile-learn-1.jpg')`,
					backgroundSize: 'cover',
					backgroundPosition: 'center'
				}}
			>
				<div className={classes.jumbtron}>
					<div className={classes.content}>
						<FuseAnimate animation="transition.slideUpIn" delay={300}>
							<Typography variant="h3" color="inherit" className="font-light mt-128 mb-20">
								{t('web.title')}
							</Typography>
						</FuseAnimate>

						<FuseAnimate delay={400}>
							<Typography variant="subtitle1" color="inherit" className="m-0 mt-16">
								{t('web.intro')}
							</Typography>
						</FuseAnimate>
					</div>
				</div>
			</div>

			<FuseAnimate animation={{ translateX: [0, '100%'] }}>
				<Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
					<CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128">
						{/* <Typography variant="h6" align="center" className="md:w-full mb-32 border-1 border-blue py-20">
							当前已有<span className={classes.red}>24587</span>人加入课程!
						</Typography> */}
						<Typography variant="h6" align="center" className="md:w-full mb-32">
							{t('user login')}
						</Typography>
						<form name="loginForm" className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
							<TextField
								className="mb-16"
								label="Username"
								autoFocus
								type="text"
								name="email"
								value={form.email}
								onChange={handleChange}
								variant="outlined"
								required
								fullWidth
							/>

							<TextField
								className="mb-16"
								label="Password"
								type="password"
								name="password"
								value={form.password}
								onChange={handleChange}
								variant="outlined"
								required
								fullWidth
							/>

							<div className="flex items-center justify-between">
								<FormControl>
									<FormControlLabel
										control={
											<Checkbox name="remember" checked={form.remember} onChange={handleChange} />
										}
										label={t('remember me')}
									/>
								</FormControl>

								{/* <Link className="font-medium" to="/pages/auth/forgot-password-2">
									忘记密码?
								</Link> */}
							</div>

							<Button
								variant="contained"
								color="primary"
								className="w-full mx-auto mt-16"
								aria-label="LOG IN"
								disabled={!isFormValid()}
								type="submit"
							>
								{t('login')}
							</Button>
						</form>

						{/* <div className="flex flex-col items-center justify-center pt-32 pb-24">
							<span className="font-medium">没有账号?</span>
							<Link className="font-medium" to="/register">
								创建账号
							</Link>
						</div> */}
					</CardContent>
				</Card>
			</FuseAnimate>
		</div>
	);
}

export default Login2Page;
