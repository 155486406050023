import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';

const IndividualAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			auth: authRoles.student,
			path: '/apps/individual/testRecords/:testRecordId',
			component: React.lazy(() => import('./testRecords/practiceTestRecord'))
		},
		{
			auth: authRoles.student,
			path: '/apps/individual/levelTestRecords/:testRecordId',
			component: React.lazy(() => import('./testRecords/levelTestRecord'))
		},
		{
			auth: authRoles.student,
			path: '/apps/individual/testRecords',
			component: React.lazy(() => import('./testRecords/testRecords'))
		},
		{
			auth: authRoles.student,
			path: '/apps/individual/info',
			component: React.lazy(() => import('./info/info'))
		},
		{
			auth: authRoles.student,
			path: '/apps/individual/profile/:userId',
			component: React.lazy(() => import('./profile/profile'))
		},
		{
			auth: authRoles.student,
			path: '/apps/individual/profile',
			component: React.lazy(() => import('./profile/profile'))
		},
		{
			auth: authRoles.student,
			path: '/apps/individual/level/test',
			component: React.lazy(() => import('./level/levelExam/exam'))
		},
		{
			auth: authRoles.student,
			path: '/apps/individual/level',
			component: React.lazy(() => import('./level/level'))
		},
		{
			auth: authRoles.onlyStudent,
			path: '/apps/individual/instructors',
			component: React.lazy(() => import('./instructors/instructors'))
		},
		{
			auth: authRoles.instructor,
			path: '/apps/individual/my-questions',
			component: React.lazy(() => import('./myQuestions/myQuestions'))
		},
		{
			auth: authRoles.instructor,
			path: '/apps/individual/my-students',
			component: React.lazy(() => import('./myStudents/myStudents'))
		},
		{
			auth: authRoles.student,
			path: '/apps/individual',
			component: () => <Redirect to="/apps/individual/info" />
		}
	]
};

export default IndividualAppConfig;
