import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from 'locales/en/translation.json';
import translationCN from 'locales/cn/translation.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	en: {
		translation: translationEN
	},
	cn: {
		translation: translationCN
	}
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'en',

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;
