import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import React from 'react';

function SavePDFButton(props) {
	return (
		<Tooltip title="Save as pdf" placement="bottom">
			<IconButton onClick={() => window.print()} className={clsx('w-40 h-40', props.className)}>
				<Icon>{'description'}</Icon>
			</IconButton>
		</Tooltip>
	);
}

export default SavePDFButton;
