import setting from '../env';

const url = {
	production: 'https://learn.codingminds.academy/',
	development: 'http://localhost:3000/'
};

export const baseUrl = url[setting.status];

// export const shareMyWorksUrl = 'https://prod-sharemyworks-backend.herokuapp.com/api';
export const shareMyWorksUrl = 'https://backend4.sharemyworks.com/api';

export const region = 'oss-us-west-1';
export const bucket = 'cm-silicon';
export const urlPrefix = `http://${bucket}.${region}.aliyuncs.com/`;

const OSS = require('ali-oss');
export const client = new OSS({
	region: region,
	accessKeyId: 'LTAI4G7RGyRSvJ3AA1qhkwAu',
	accessKeySecret: 'exZZBjQ38WnW8jAJ5tIYlOGYfdwnt5',
	bucket: bucket
});
